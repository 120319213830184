import { BrowserRouter as Router, Route, Redirect, Switch, HashRouter } from "react-router-dom";
import { lazy, Suspense, useEffect, useState } from 'react'
import Logo from 'assets/img/logo.png'
import { useDispatch } from 'react-redux'
import listUrl from './listUrl'
import useFetch from "hooks/useFetch";

const Login = lazy(() => import('apps/login'))
const Register = lazy(() => import('apps/register'))
const Home = lazy(() => import('apps/home'))
const Wakaf = lazy(() => import('apps/wakaf'))
const Transaction = lazy(() => import('apps/transaction'))
const PaymentInstruction = lazy(() => import('apps/paymentinstruction'))
const SuccessPage = lazy(() => import('apps/success'))
const Inbox = lazy(() => import('apps/inbox'))
const History = lazy(() => import('apps/history'))
const Services = lazy(() => import('apps/services'))
const Referral = lazy(() => import('apps/referral'))
const Contact = lazy(() => import('apps/contact'))
const Profile = lazy(() => import('apps/profile'))
const Privacy = lazy(() => import('apps/privacy'))
const Faq = lazy(() => import('apps/faq'))
const PaymentGateway = lazy(() => import('apps/paymentGatewway'))
const DetailProject = lazy(() => import('apps/detail'))
const ForgotPassword = lazy(() => import('apps/forgotpassword'))
const NewPassword = lazy(() => import('apps/newpassword'))
const Verification = lazy(() => import('apps/verification'))
const TransactionZakat = lazy(() => import('apps/transactionZakat'))
const FullLayout = lazy(() => import('layouts/Full'))
const MainLayout = lazy(() => import('layouts/Main'))


const Loading = () => {
    return (
        <div className="w-full h-screen flex justify-center">
            <div className="self-center">
                <img src={Logo} alt="logo" className="w-1/6 mx-auto" />
            </div>
        </div>
    )
}

export default (props) => {
    const dispatch = useDispatch()
    const fetchApi = useFetch()

    useEffect(() => {
        handleSession()
        setConfig()
    }, [])
    
    const handleSession = () => {
        var session = sessionStorage.getItem('session')
        if (!session) return
        session = JSON.parse(session)
        dispatch({ type: "SESSION_DATA", payload: session })
    }

    const setConfig = async () => {
        var res = await fetchApi({ url: "/api/xip-ziswaf/v1/ziswaf_parameter/company_profile", method: "GET"})
        dispatch({ type: "COMPANY_DATA", payload: res?.data?.details })
        res = await fetchApi({ url: "/api/xip-ziswaf/v1/ziswaf_parameter/app_config", method: "GET"})
        dispatch({ type: "APP_CONFIG", payload: res?.data?.details })
    }

    return (
        <Router>
            <Switch>
                <Redirect to="/" exact from="/home" />
                <Suspense fallback={<Loading />}>
                    <Route path={['/signin', '/signup', '/referral', '/payment/instruction', '/thanks-page', '/forgotpassword', '/newpassword', '/verification', '/transaction/:id', '/transaction/zakat/:id', '/payment-gateway']} exact>
                        <FullLayout>
                            <Switch>
                                <Route path="/signin" component={Login} />
                                <Route path="/signup" component={Register} />
                                <Route path="/referral" component={Referral} />
                                <Route path="/payment/instruction" exact component={PaymentInstruction} />
                                <Route path="/thanks-page" exact component={SuccessPage} />
                                <Route path="/forgotpassword" exact component={ForgotPassword} />
                                <Route path="/newpassword" component={NewPassword} />
                                <Route path="/verification" exact component={Verification} />
                                <Route path="/transaction/:id" exact component={Transaction} />
                                <Route path="/transaction/zakat/:id" exact component={TransactionZakat} />
                                <Route path="/payment-gateway" exact component={PaymentGateway} />
                            </Switch>
                        </FullLayout>
                    </Route>
                    <Route path={listUrl} exact>
                        <MainLayout>
                            <Switch>
                                <Route path="/" hide exact title="Home" component={Home} />
                                <Route path="/contact" back title="Contact Us" component={Contact} />
                                <Route path="/wakaf" back title="List Project" exact component={Wakaf} />
                                <Route path="/detail/:id" back title="Project Detail" exact component={DetailProject} />
                                <Route path="/detail" back title="Project Detail" exact component={DetailProject} />
                                <Route path="/inbox" exact component={Inbox} />
                                <Route path="/history" hide title="Kisah Amal" exact component={History} />
                                <Route path="/services" back title="Services" exact component={Services} />
                                <Route path="/privacy" back title="Privacy Policy" exact component={Privacy} />
                                <Route path="/faq" back title="FAQ" exact component={Faq} />
                                <Route path="/profile" exact component={Profile} />
                            </Switch>
                        </MainLayout>
                    </Route>
                </Suspense>
            </Switch>
        </Router>
    )
}
