import axios from 'axios'

// import baseUrl from './base.config'
import { v4 as uuidv4 } from 'uuid';
import { v5 as uuidv5 } from 'uuid';
import nacl from "tweetnacl"
import naclUtil from 'tweetnacl-util'

class BaseApi {
    // getSignature(request_key, request_id, stringToHash) {
    //     try {
    //         const key = naclUtil.decodeBase64(request_key);
    //         const formattedStringToHash = naclUtil.decodeUTF8(stringToHash);
    //         const hashedStringToHash = nacl.hash(formattedStringToHash);
    //         const keys = nacl.box.keyPair();
    //         const nonce = nacl.randomBytes(nacl.box.nonceLength);
    //         const encryptedStringToHash = nacl.box(hashedStringToHash, nonce, key, keys.secretKey);
    //         const endcodedStringToHash = naclUtil.encodeBase64(encryptedStringToHash);

    //         const RequestId = request_id;
    //         const RequestNonce = naclUtil.encodeBase64(nonce);
    //         const RequestKey = naclUtil.encodeBase64(keys.publicKey);

    //         //generate signature: base64 JSON stringify of requestId, key, nounce, encrtypted data
    //         const prepareSignature = {
    //             RequestId: RequestId,
    //             RequestKey: RequestKey,
    //             RequestNonce: RequestNonce,
    //             RequestData: endcodedStringToHash,
    //         };
    //         const decodeUTF8Signature = naclUtil.decodeUTF8(JSON.stringify(prepareSignature))
    //         const Signature = naclUtil.encodeBase64(decodeUTF8Signature);
    //         return Signature
    //     }
    //     catch (error) {
    //         console.log("Signature error to encrypted", error)
    //         return null
    //     }
    // }
    isEmpty(data) {
        return data && Object.keys(data).length === 0
    }
    api() {
        const baseApi = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        });

        baseApi.interceptors.request.use(async (config) => {
            const isEmptyObj = this.isEmpty(config.data);
            const uri = config?.data?.requestUrl || config.url;
            const stringToHash = uri.concat(isEmptyObj || isEmptyObj === undefined ? '' : JSON.stringify(config.data));

            // Skip token injection for specific endpoints or if custom auth header is provided
            if (config.headers['Authorization']) {
                return config;
            }

            const storedUser = sessionStorage.getItem('session');
            const user = storedUser ? JSON.parse(storedUser) : null;
            const token = user?.token?.access_token;

            config.headers = {
                Authorization: 'Bearer ' + (token || ''),
            };

            return config;
        }, function (error) {
            return Promise.reject(error);
        });

        baseApi.interceptors.response.use((response) => {
            return response.data ? response.data : response;
        }, async (error) => {
            const { response = {} } = error;

            if (response?.status === 401) {
                try {
                    const storedUser = sessionStorage.getItem('session');
                    const user = storedUser ? JSON.parse(storedUser) : null;
                    const refreshToken = user?.token?.refresh_token;

                    if (!refreshToken) {
                        throw new Error('No refresh token available');
                    }

                    const refreshResponse = await this.api().post('/api/customer/v1/user/refresh-token', {
                        refresh_token: refreshToken
                    });

                    // Update session with new access token
                    user.token.access_token = refreshResponse.data.access_token;
                    sessionStorage.setItem('session', JSON.stringify(user));

                    // Retry the original request with the new token
                    const originalRequest = error.config;
                    originalRequest.headers['Authorization'] = 'Bearer ' + refreshResponse.data.access_token;
                    return axios(originalRequest);

                } catch (refreshError) {
                    // Clear session and redirect to sign-in on refresh failure
                    sessionStorage.clear();
                    window.location.replace("/signin");
                    return Promise.reject(refreshError);
                }
            }

            return Promise.reject(error);
        });

        return baseApi;
    }
    // async generateKey() {
    //     var key = uuidv4()
    //     var request_id = uuidv5("1wakaf", key)
    //     const res = await axios.get(`${process.env.REACT_APP_API_URL}/batches/generaterequestkey/${request_id}`)
    //     return res.data.data
    // }
}

export default BaseApi
